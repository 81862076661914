<div class="gn-w-full" [style.color]="textColor" #groupPaymentEl>
    <h3 class="gn-text-lg gn-font-semibold gn-m-0 gn-mb-2">{{ 'booker.your_booking' | translate }}</h3>
    <p class="gn-m-0 gn-text-base">{{ package?.name }} {{ venue?.name }} on {{ date | date:'dd MMMM yyyy' }} at {{ session?.session_name }}</p>
    <div class="gn-w-full gn-pl-4 gn-mb-4">
        <p *ngFor="let option of priceOptions" class="gn-m-0 gn-text-base">{{ option.qty_requested }} x {{ option.name }} {{ (promo ? option.price_calc : option.price) | currency:venue.currency }}</p>
    </div>
    <div class="gn-w-full gn-pl-4 gn-mb-4">
        <p *ngFor="let extra of filteredExtras" class="gn-m-0 gn-text-base">{{ extra.qty_requested }} x {{ extra.name }} {{ (promo ? extra?.price_calc || extra.price : extra.price) | currency:venue.currency }}</p>
    </div>
    <h3 class="gn-text-lg gn-font-semibold gn-mb-2">{{ 'misc.payment' | translate }}</h3>
    <div class="gn-w-full gn-flex gn-flex-col md:gn-flex-row gn-items-start md:gn-items-center gn-mb-2">
        <div class="md:gn-w-1/2 gn-w-full gn-rounded gn-flex gn-border gn-border-aaccent focus:gn-border-accent gn-overflow-hidden gn-text-accent">
            <input type="text" name="promocode" [(ngModel)]="promocode" (keydown.enter)="applyPromoVoucher()" class="gn-bg-white gn-w-8/12 gn-p-4 gn-text-gray-700 gn-flex-1" placeholder="Promo/Voucher code" style="min-width: 100px;" />
            <button type="button" class="gn-px-4 hover:gn-bg-aaccent gn-w-34/12" (click)="applyPromoVoucher()" style="background: rgba(74, 85, 104, 0.1);">
                <span *ngIf="!promoLoading">{{ 'payments.redeem' | translate }}</span>
                <div class="gn-spinner-loader" *ngIf="promoLoading">
                    <div class="gn-bounce1"></div>
                    <div class="gn-bounce2"></div>
                    <div class="gn-bounce3"></div>
                </div>
            </button>
        </div>
        <div class="gn-flex-1 gn-ml-4">
            <div *ngIf="promo" class="md:gn-mt-0 gn-mt-2 gn-flex gn-items-center gn-justify-between">
                <div class="flex-1">
                    <p class="gn-text-gray-700 gn-font-medium">{{ 'payments.promocode' | translate }}: {{ promo.code }}</p>
                </div>
                <button
                    (click)="removePromo()" type="button"
                    class="gn-text-red-400 hover:gn-text-red-600 gn-font-semibold gn-text-2xl gn-leading-none gn-ml-4">&times;</button>
            </div>
        </div>
    </div>
    <div class="gn-w-full gn-flex gn-mb-2" *ngIf="options.length">
        <div class="md:gn-w-1/2 gn-w-full md:gn-pr-1 gn-mb-2 md:gn-mb-0">
            <ng-select
                [items]="options"
                (change)="getTotals()"
                bindLabel="name"
                bindValue="val"
                [clearable]="false"
                [searchable]="false"
                [(ngModel)]="option"
                name="option"
            ></ng-select>
        </div>
        <div class="md:gn-w-1/2 gn-w-full md:gn-pl-1" *ngIf="option !== 'full'">
            <div class="gn-w-full gn-h-full gn-flex gn-items-center gn-justify-center gn-rounded gn-bg-yellow-500">
                <p class="gn-font-medium gn-text-accent">
                    {{ dueBy }}
                </p>
            </div>
        </div>
    </div>
    <div class="gn-w-full gn-rounded gn-border gn-border-solid gn-p-3 gn-flex gn-items-start gn-justify-end gn-mb-2" [style.background]="accentColor" [style.border-color]="textColor">
        <div class="gn-uppercase gn-mr-2">
            <p class="gn-text-base gn-m-0">{{ 'totals.cost' | translate }}:</p>
            <div class="gn-relative gn-flex gn-items-center" *ngFor="let voucher of vouchers; index as i">
                <button
                    (click)="removeVoucher(i)"
                    type="button"
                    class="gn-text-red-400 hover:gn-text-red-600 gn-font-semibold gn-text-normal gn-leading-none gn-absolute gn--ml-4 gn-left-0"
                >&times;</button>
                <p>{{ voucher.name }}:</p>
            </div>
            <p class="gn-text-base gn-m-0" *ngIf="fee > 0.00">{{ 'totals.fee' | translate }}:</p>
            <p class="gn-text-base gn-m-0">{{ 'totals.due' | translate }}:</p>
        </div>
        <div>
            <p class="gn-text-base gn-m-0">{{ selectedOption?.price || total | currency:venue?.currency }}</p>
            <p *ngFor="let voucher of vouchers">-{{ voucher.balance_value | currency:venue?.currency:'symbol-narrow' }}</p>
            <p class="gn-text-base gn-m-0" *ngIf="fee > 0.00">{{ fee | currency:venue?.currency }}</p>
            <p class="gn-text-base gn-m-0">{{ dueNow | currency:venue?.currency }}</p>
        </div>
    </div>
    <div class="gn-w-full gn-mb-2">
        <ng-template #flexiDateInfoEl>
            <p style="text-align: left; font-size: 0.8rem; margin: 0;" class="gn-font">
                Book your experience on {{ company.cms_config.flexi.name || 'Flexi Date' }} and you will be able to reschedule your event, to a future date of your choosing (depending on availability), at not extra charge.<br />
                {{ company.cms_config.flexi.name || 'Flexi Date' }}: Booking can be reschedule up to 14 days prior to your event date<br /><br />
                For peace of mind {{ company.cms_config.flexi.name || 'Flexi Date' }} is the most popular choice when it comes to making adjustments to your event date due to unforeseen circumstances.<br />
            </p>
        </ng-template>
        <label *ngIf="flexi > 0" class="gn-flex gn-items-center gn-mb-2" [tooltip]="flexiDateInfoEl" contentType="template">
            <input type="checkbox" [formControl]="form.get('flexi')" (change)="getTotals()" class="gn-mr-4 gn-h-8 gn-w-8" />
            <span>
                Add <b>{{ company.cms_config.flexi.name || 'Flexi Date' }}</b> option for peace of mind for {{ flexi | currency:venue.currency }}
            </span>
        </label>
        <label class="gn-flex gn-items-center">
            <input type="checkbox" [formControl]="form.get('terms')" class="gn-mr-4 gn-h-8 gn-w-8" />
            <span>
                Tick this box to show that you have read and agreed to our <a (click)="showTerms = !showTerms" class="gn-underline" href="javascript:void(0)">Terms and Conditions</a>
            </span>
        </label>
        <div class="gn-w-full gn-text-sm gn-border gn-p-5" [style.border-color]="textColor" *ngIf="showTerms">
            <div class="gn-w-full" [style.color]="textColor" *ngFor="let item of termsText; last as isLast" [class.gn-mb-2]="!isLast">
                <p class="gn-text-semibold">{{ item.title }}</p>
                <div [innerHTML]="item.text"></div>
            </div>
        </div>
    </div>
    <p class="gn-mt-4 gn-mb-1 gn-text-sm gn-text-gray-700 gn-font-medium" *ngIf="duration">{{ message }} {{ timer }}</p>
    <!-- <p class="gn-mt-4 gn-mb-1 gn-text-sm gn-text-gray-700 gn-font-medium" *ngIf="paymentMethods > 1 && dueNow > 0">Select a payment method to secure your booking.</p> -->
    <div class="gn-w-full gn-flex gn-flex-col md:gn-flex-row gn-items-start gn-relative" [ngClass]="form.get('terms').value ? '' : 'gn-opacity-50'" [class.gn-hidden]="dueNow <= 0">
        <div style="height: 55px" class="gn-flex-1 gn-w-full md:gn-w-auto gn-mb-2 md:gn-mb-0 gn-rounded gn-border gn-border-solid gn-bg-white gn-cursor-pointer" (click)="toggleCard()" [style.border-color]="textColor" *ngIf="profiles.includes('stripe')" [class.gn-mr-2]="profiles.includes('paypal') || showPaymentRequest">
            <div class="gn-w-full gn-flex gn-items-center gn-justify-center gn-h-full">
                <img class="gn-h-8 gn-mr-2" src="https://geronigo.gitlab.io/bookit-light/assets/visa.svg" />
                <img class="gn-h-8 gn-mr-2" src="https://geronigo.gitlab.io/bookit-light/assets/master-card.svg" />
                <img class="gn-h-8 gn-mr-2" src="https://geronigo.gitlab.io/bookit-light/assets/american-express.svg" />
                <span class="gn-text-gray-700 gn-text-base">{{ 'payments.card_payment' | translate }}</span>
            </div>
        </div>
        <div class="gn-flex-1 gn-w-full md:gn-w-auto gn-mb-2 md:gn-mb-0" [class.gn-hidden]="!profiles.includes('paypal')" [class.gn-mr-2]="showPaymentRequest" [class.gn-pointer-events-none]="loading">
            <div #paypalEl></div>
        </div>
        <div class="gn-flex-1 gn-w-full md:gn-w-auto md:gn-mb-0" [class.gn-hidden]="!showPaymentRequest" [class.gn-pointer-events-none]="loading || !clickAllowed">
            <div #paymentRequestEl (click)="disableDoubleClick($event)"></div>
        </div>
        <div class="gn-absolute gn-inset-0" style="z-index: 999;" *ngIf="!form.get('terms').value" (click)="form.get('terms').markAsTouched()" (mouseenter)="form.get('terms').markAsTouched()">
        </div>
    </div>
    <div class="gn-w-full gn-mt-2" [class.gn-hidden]="!showCard || dueNow <= 0">
        <form [formGroup]="form" (ngSubmit)="makeTransaction()">
            <div class="gn-w-full gn-flex gn-flex-col md:gn-flex-row gn-items-start gn-mb-2">
                <div class="gn-w-full md:gn-w-1/2 md:gn-pr-1 gn-mb-2 md:gn-mb-0">
                    <input class="gn-rounded gn-bg-white gn-w-full gn-font-normal gn-p-2 gn-border gn-border-solid gn-border-gray-300 gn-text-gray-700" type="text" formControlName="name" placeholder="Full Name*" required/>
                </div>
                <div class="gn-w-full md:gn-w-1/2 md:gn-pl-1">
                    <input class="gn-rounded gn-bg-white gn-w-full gn-font-normal gn-p-2 gn-border gn-border-solid gn-border-gray-300 gn-text-gray-700" type="email" formControlName="email" placeholder="{{ 'customer_data.email' | translate }}*" email required/>
                </div>
            </div>
            <div class="gn-w-full gn-flex gn-flex-col md:gn-flex-row gn-items-start gn-mb-2">
                <div class="gn-w-full md:gn-w-1/2 md:gn-pr-1 gn-mb-2 md:gn-mb-0">
                    <input class="gn-rounded gn-bg-white gn-w-full gn-font-normal gn-p-2 gn-border gn-border-solid gn-border-gray-300 gn-text-gray-700" type="text" formControlName="address" placeholder="Street Address*" required/>
                </div>
                <div class="gn-w-full md:gn-w-1/2 md:gn-pl-1">
                    <input  class="gn-rounded gn-bg-white gn-w-full gn-font-normal gn-p-2 gn-border gn-border-solid gn-border-gray-300 gn-text-gray-700" type="text" (input)="setZip()" formControlName="card_zip" placeholder="ZIP / Post Code*" required/>
                </div>
            </div>
            <div *ngIf="isTestMode"><div class="gn-bg-red-100 gn-text-center gn-text-red-500 gn-font-bold">Test Mode</div>
            <div class="gn-text-red-500 gn-text-center">Use card number 4242 4242 4242 4242<span [cdkCopyToClipboard]="'424242424242424212230123'" tooltip="Copy to clipboard"><fa-icon [icon]="faCopy"></fa-icon> </span>, with future expiry number and 123 as the CVC </div>
            </div>
            <div class="gn-rounded gn-bg-white gn-w-full gn-p-3 gn-border gn-border-solid gn-border-gray-300">
                <div #cardEl></div>
            </div>
            <div class="gn-mt-4 gn-text-right">
                <button [disabled]="form.invalid || loading" class="gn-uppercase gn-rounded gn-text-md gn-font-semibold gn-px-8 gn-py-3 hover:gn-opacity-75" [style.background]="primaryColor" type="submit">
                    <span *ngIf="!loading" class="gn-text">{{ 'buttons.pay_now' | translate }}</span>
                    <span *ngIf="!loading" class="gn-hint">{{ hint | translate }}</span>
                    <div class="gn-spinner-loader gn-spinner-inverse" *ngIf="loading">
                        <div class="gn-bounce1"></div>
                        <div class="gn-bounce2"></div>
                        <div class="gn-bounce3"></div>
                    </div>
                </button>
            </div>
        </form>
    </div>
    <div class="gn-mt-4 gn-flex gn-items-center gn-justify-between">
        <a href="javascript:void(0)" [style.color]="textColor" (click)="updateField.emit({ field: 'step', value: 'customer' })">
            <fa-icon [icon]="faChevronLeft"></fa-icon> {{ 'buttons.back' | translate }}
        </a>
        <button *ngIf="dueNow <= 0" [disabled]="loading || !form.get('terms').value" class="gn-uppercase gn-rounded gn-text-md gn-font-semibold gn-px-8 gn-py-3 hover:gn-opacity-75" [style.background]="primaryColor" type="button" (click)="makeTransaction()">
            <span *ngIf="!loading" class="gn-text">{{ 'buttons.book_now' | translate }}</span>
            <span *ngIf="!loading" class="gn-hint">Accept Terms</span>
            <div class="gn-spinner-loader gn-spinner-inverse" *ngIf="loading">
                <div class="gn-bounce1"></div>
                <div class="gn-bounce2"></div>
                <div class="gn-bounce3"></div>
            </div>
        </button>
    </div>
</div>
<div class="gn-fixed gn-flex gn-inset-0 gn-justify-center gn-items-center" style="background: rgba(0, 0, 0, 0.5);" *ngIf="modalVisible">
    <div class="gn-p-5 gn-rounded gn-bg-white" style="max-width: 500px">
        <p class="gn-text-gray-700">Do you wish to continue with this booking?</p>
        <div class="gn-w-full gn-flex gn-items-center gn-justify-end gn-mt-4">
            <button [disabled]="availLoading" class="gn-uppercase gn-rounded gn-text-md gn-font-semibold gn-px-4 gn-py-1 hover:gn-opacity-75" [style.background]="primaryColor" type="button" (click)="resetTimer()">
                <span *ngIf="!availLoading">Yes</span>
                <div class="gn-spinner-loader gn-spinner-inverse" *ngIf="availLoading">
                    <div class="gn-bounce1"></div>
                    <div class="gn-bounce2"></div>
                    <div class="gn-bounce3"></div>
                </div>
            </button>
            <button [disabled]="availLoading" class="gn-uppercase gn-ml-2 gn-rounded gn-text-md gn-font-semibold gn-px-4 gn-py-1 hover:gn-opacity-75" [style.background]="accentColor" type="button" (click)="tostart.emit()">
                No
            </button>
        </div>
    </div>
</div>
