<ng-template #headerTemplate let-days="days" let-locale="locale">
    <div class="gn-w-full gn-flex gn-items-center gn-font-semibold gn-border-b gn-border-solid" [style.border-color]="textColor" [style.color]="textColor">
        <div class="gn-text-center gn-flex-1 gn-py-2">
            <p class="gn-text-base gn-m-0">{{ 'week_days.mon' | translate }}</p>
        </div>
        <div class="gn-text-center gn-flex-1 gn-py-2">
            <p class="gn-text-base gn-m-0">{{ 'week_days.tue' | translate }}</p>
        </div>
        <div class="gn-text-center gn-flex-1 gn-py-2">
            <p class="gn-text-base gn-m-0">{{ 'week_days.wed' | translate }}</p>
        </div>
        <div class="gn-text-center gn-flex-1 gn-py-2">
            <p class="gn-text-base gn-m-0">{{ 'week_days.thu' | translate }}</p>
        </div>
        <div class="gn-text-center gn-flex-1 gn-py-2">
            <p class="gn-text-base gn-m-0">{{ 'week_days.fri' | translate }}</p>
        </div>
        <div class="gn-text-center gn-flex-1 gn-py-2">
            <p class="gn-text-base gn-m-0">{{ 'week_days.sat' | translate }}</p>
        </div>
        <div class="gn-text-center gn-flex-1 gn-py-2">
            <p class="gn-text-base gn-m-0">{{ 'week_days.sun' | translate }}</p>
        </div>
    </div>
</ng-template>

<ng-template  #cellTemplate let-day="day" let-locale="locale">
    <div class="gn-flex gn-justify-center gn-items-center gn-py-2">
        <div
            class="gn-rounded-full gn-flex gn-justify-center gn-items-center gn-border-solid gn-w-8 gn-h-8 gn-month-day"
            [class.gn-border-4]="date?.getTime() === day.date.getTime()"
            [style.border-color]="textColor"
        >
            <span
                [style.color]="textColor"
                [class.gn-opacity-50]="day.isPast"
                class="gn-font-bold gn-text-base"
            >{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
        </div>
    </div>
</ng-template>

<div class="gn-w-full gn-flex gn-items-start md:gn-flex-row gn-flex-col">
    <div class="gn-w-full md:gn-w-1/2 md:gn-pr-2 gn-mb-4 md:gn-mb-0">
        <div class="gn-w-full gn-rounded gn-overflow-hidden gn-border-2 gn-border-solid gn-relative" [style.border-color]="textColor">
            <div class="gn-w-full gn-text-white gn-flex gn-items-center gn-justify-between gn-py-3 gn-px-5" [style.background]="primaryColor">
                <a href="javascript:void(0)" class="gn-uppercase gn-text-sm gn-flex gn-items-center" (click)="changeViewDate('prev')">
                    <fa-icon [icon]="faChevronLeft"></fa-icon>
                    <span>&nbsp;{{ 'buttons.prev' | translate }}</span>
                </a>
                <h3 class="gn-text-xl gn-font-semibold gn-m-0">{{ viewDate | date:'MMMM yyyy' }}</h3>
                <a href="javascript:void(0)" class="gn-uppercase gn-flex gn-items-center gn-text-sm" (click)="changeViewDate('next')">
                    <span>{{ 'buttons.next' | translate }}&nbsp;</span>
                    <fa-icon [icon]="faChevronRight"></fa-icon>
                </a>
            </div>
            <div class="gn-w-full">
                <mwl-calendar-month-view
                    [viewDate]="viewDate"
                    [headerTemplate]="headerTemplate"
                    [cellTemplate]="cellTemplate"
                    (dayClicked)="dayClicked($event)"
                    (beforeViewRender)="beforeMonthViewRender($event)"
                    [refresh]="refresh"
                    weekStartsOn="1"
                ></mwl-calendar-month-view>
            </div>
            <div class="gn-w-full gn-flex gn-items-center gn-justify-center gn-py-3 gn-border-t-2 gn-border-solid" [style.border-color]="textColor" [style.color]="textColor">
                <div class="gn-mr-4 gn-flex gn-items-center">
                    <span class="gn-w-6 gn-h-6 gn-bg-main gn-rounded-full gn-mr-2"></span>
                    <span class="gn-text-sm">{{ 'avail_status.closed' | translate }}</span>
                </div>
                <div class="gn-mr-4 gn-flex gn-items-center">
                    <span class="gn-w-6 gn-h-6 gn-bg-limited gn-rounded-full gn-mr-2"></span>
                    <span class="gn-text-sm">{{ 'avail_status.limited' | translate }}</span>
                </div>
                <div class="gn-flex gn-items-center">
                    <span class="gn-w-6 gn-h-6 gn-bg-available gn-rounded-full gn-mr-2"></span>
                    <span class="gn-text-sm">{{ 'avail_status.available' | translate }}</span>
                </div>
            </div>
            <div class="gn-absolute gn-inset-0 gn-flex gn-items-center gn-justify-center" style="background: rgba(0, 0, 0, 0.5)" *ngIf="availabilityLoading">
                <div class="gn-w-32 gn-h-32 gn-mx-auto gn-rounded-full gn-border-t-2 gn-border-r-2 gn-border-solid gn-spin" [style.border-color]="primaryColor"></div>
            </div>
        </div>
    </div>
    <div class="gn-w-full md:gn-w-1/2 md:gn-pl-2">
        <div class="gn-w-full gn-mb-2">
            <ng-select
            [placeholder]="'booker.select_activity' | translate"
            bindLabel="name"
            bindValue="activity_id"
            [clearable]="false"
            [items]="activities"
            [searchable]="false"
            [(ngModel)]="activityId"
            (change)="activityChanged($event)"
            *ngIf="activities.length>1"
        >
            </ng-select>
        </div>
        <div class="gn-w-full gn-mb-2" *ngIf="activityId || activities.length === 1">
            <ng-select 
                [placeholder]="'booker.select_package' | translate"
                bindLabel="name"
                name="package"
                [clearable]="true"
                [items]="packages"
                [searchable]="false"
                [(ngModel)]="package"
                [disabled]="!activityId && activities.length>1"
                (change)="packageChanged($event)"
            >
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div class="gn-flex gn-items-center gn-justify-between gn-mb-2"
                    [ngClass]="item?.enabled ? '' : 'gn-text-red-300'">
                        <div class="gn-flex gn-flex-col">
                            <h6 class="gn-text-base gn-m-0">{{ item.name }}</h6>
                            <p class=" gn-text-sm gn-m-0" [ngClass]="item?.enabled ? 'gn-text-gray-500' : 'gn-text-red-300'">{{ item.min_max }}</p>
                        </div>
                        <div class="gn-flex gn-items-center" *ngIf="item.min_age > 0">
                            <div class="gn-age-restriction">
                                <p class="gn-leading-none gn-text-sm gn-m-0">{{ item.min_age }}+</p>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-select>
        </div>
        <div class="gn-w-full gn-p-3 gn-flex gn-items-center gn-justify-center" *ngIf="!package" [style.color]="textColor">
            <!--div class="gn-w-32 gn-h-32 gn-mx-auto gn-rounded-full gn-border-t-2 gn-border-r-2 gn-border-solid gn-spin" [style.border-color]="primaryColor"></div-->
            <div class="gn-w-full gn-px-3 gn-py-2 gn-rounded gn-border gn-border-solid gn-flex gn-items-center">
                <p class="gn-w-full gn-text-base gn-m-0 gn-font-semibold gn-uppercase gn-text-center">
                    Select a package
                </p>
            </div>
        </div>
        <div class="gn-w-full" [style.color]="textColor" *ngIf="package">
            <div class="gn-text-sm" [innerHTML]="package.desc"></div>
            <div class="gn-w-full gn-flex md:gn-flex-row gn-flex-col gn-items-start gn-my-3">
                <div class="gn-w-full gn-mb-2 md:gn-mb-0 md:gn-w-1/2">
                    <p class="gn-font-bold gn-uppercase gn-m-0 gn-mb-1">{{ 'booker.restrictions' | translate }}</p>
                    <div class="gn-w-full gn-flex gn-flex-col gn-items-start">
                        <p class="gn-m-0 gn-text-base" *ngFor="let restriction of package?.restrictions || []">
                            <fa-icon *ngIf="restriction.icon" [fixedWidth]="true" [icon]="restriction.icon"></fa-icon> {{ restriction.name }}: {{ restriction.descriptor }}
                        </p>
                    </div>
                </div>
                <div class="gn-w-full md:gn-w-1/2" *ngIf="package.includes && package.includes.length">
                    <p class="gn-font-bold gn-uppercase gn-m-0 gn-mb-1">{{ 'booker.includes' | translate }}</p>
                    <p *ngFor="let include of package.includes" class="gn-m-0 gn-mt-1 gn-text-base">– {{ include.name }}{{ include.descriptor ? ':' + include.descriptor :'' }}</p>
                </div>
            </div>
            <p *ngIf="freePaintballs" class="gn-m-0 gn-text-base">
                {{ 'booker.offer' | translate }}
            </p>

            <div *ngIf="depositProfile?.deposit_lead" class="gn-px-5 gn-py-4 gn-text-center"
            >
                <p class=" gn-text-lg gn-m-0 gn-mb-1" 
                tooltipClass="note-tooltip"
                tooltip="<p>Secure your event with a small deposit per person if your event is in the future.</p><p>Outstanding balances are due {{depositProfile.deposit_lead}} days prior to the event date.</p><p>Ideal if booking for large groups, waiting for final numbers or securing the date while finalising other plans surrounding your event.</p>">
                Secure your Booking with a <span class="gn-font-bold ">{{depositProfile.deposit| currency:venue?.currency}} DEPOSIT</span> Per person</p>
            </div>

            <div class="gn-w-full gn-mt-2">
                <div class="gn-w-full gn-p-3 gn-flex gn-items-center gn-justify-center" *ngIf="!sessions || !sessions.length">
                    <!--div class="gn-w-32 gn-h-32 gn-mx-auto gn-rounded-full gn-border-t-2 gn-border-r-2 gn-border-solid gn-spin" [style.border-color]="primaryColor"></div-->

                    <div><p class="gn-text-center gn-text-lg gn-font-semibold gn-uppercase gn-text-red-500">{{viewDate | date:'d MMMM yyyy'}}</p>
                        <div class="gn-w-full gn-px-3 gn-py-2 gn-rounded gn-border gn-border-solid gn-border-red-300 gn-flex gn-items-center">
                            <p class="gn-w-full gn-text-base gn-text-red-500 gn-m-0 gn-font-semibold gn-uppercase gn-text-center">
                                No session Available. <br/>
                                Please Select Another Date
                                <ng-container *ngIf="venue.company_id == 2"> Or Call US</ng-container>
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    class="gn-w-full gn-px-3 gn-py-2 gn-rounded gn-border gn-border-solid gn-border-gray-300 gn-flex gn-items-center gn-cursor-pointer"
                    *ngFor="let item of sessions; last as lastSession"
                    [class.gn-mb-2]="!lastSession"
                    [class.hover:gn-bg-gray-400]="item.session_id !== session?.session_id"
                    [style.background-color]="item.session_id === session?.session_id ? primaryColor : '#f7fafc'"
                    [style.color]="item.session_id === session?.session_id ? textColor : '#2d3748'"
                    [class.gn-pointer-events-none]="item.avail_status === 'closed'"
                    [class.shake]="shake"
                    (click)="setSession(item)"
                >
                    <div class="gn-w-6/12">
                        <p class="gn-text-base gn-text-gray-700 gn-m-0" >
                            <span *ngIf="tooltip" 
                            tooltipClass="note-tooltip"
                            placement="right"
                            [tooltip]="this.tooltip" class="gn-mr-4 gn-aspect-square gn-rounded-full gn-bg-blue-300 gn-text-center gn-px-2">i</span>{{ item.session_name }}<span *ngIf="item.start_time && item.start_time !== '00:00'">&nbsp;({{ item.start_time }}{{ item.end_time && item.end_time !== '00:00' ? ' - ' + item.end_time : '' }})</span>
                        </p>
                    </div>
                    <div class="gn-w-3/12 gn-flex gn-items-center">
                        <p class="gn-text-white gn-m-0 gn-font-semibold gn-text-xs gn-rounded gn-uppercase gn-leading-none gn-p-1" [ngClass]="'gn-bg-' + (item.avail_status || 'closed')">{{ item.avail_status || 'closed' }}</p>
                    </div>
                    <div class="gn-w-3/12 gn-text-right">
                        <p class="gn-font-semibold gn-text-gray-700 gn-text-base gn-m-0">{{ item.price }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="gn-w-full gn-flex gn-items-center gn-flex-col md:gn-flex-row gn-text-white gn-mt-4" *ngIf="package">
            <button
                class="gn-uppercase gn-rounded gn-text-md gn-font-semibold gn-px-4 gn-py-3 gn-w-full md:gn-w-auto hover:gn-opacity-75"
                (click)="nextStep()"
                type="button"
                [style.background]="primaryColor"
            >
                {{ 'booker.next_step' | translate }}
            </button>
        </div>
    </div>
</div>
<div class="gn-invisible gn-bg-closed gn-bg-available gn-bg-limited"></div>
