import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy, OnInit } from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { CalendarMonthViewDay, CalendarUtils } from 'angular-calendar';
import { parse, format, addDays, addMinutes, addMonths, startOfMonth, isBefore } from 'date-fns';
import { tap, finalize } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { combineLatest, Observable, Subject } from 'rxjs';

import { getOptionsMinMax, filterOptions } from '../../helpers/price-options.helpers';
import { Package, Availability, Venue } from 'src/app/models/venues.model';
import { HttpService } from 'src/app/services/http.service';
import { MemoryStorage } from 'src/app/services/memory-storage.service';
import { sortSessions } from 'src/app/helpers/utils.helpers';
import { startOfDay } from 'date-fns';

@Component({
    selector: 'app-booking-details',
    templateUrl: './booking-details.component.html',
})
export class BookingDetailsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() date: Date;
    @Input() viewDate: Date = new Date();
    @Input() venue: Venue;
    @Input() package: Package;
    @Input() session: Availability;
    @Input() activityId: string;
    @Input() primaryColor: string;
    @Input() accentColor: string;
    @Input() textColor: string;
    @Input() backgroundColor: string;
    @Input() sessionId: string;
    @Input() voucher = '1';

    @Output() updateField: EventEmitter<{ field: string, value: any }> = new EventEmitter();
    @Output() buyVoucher: EventEmitter<void> = new EventEmitter();

    public sessions: Availability[] = [];
    public refresh: Subject<any> = new Subject();
    public availabilityLoading = true;
    public freePaintballs = false;
    public shake = false;
    public packages: Package[] = [];
    public extras: string[];
    public availableExtras: Package[];
    public activities: {activity_id:number, name:string, order:number}[] = [];

    public faChevronLeft = faChevronLeft;
    public faChevronRight = faChevronRight;
    public faPlus = faPlus;
    public faMinus = faMinus;
    
    private availabilities: Availability[] = [];
    private timeout: any;

    public tooltip: string;

    public cached_data: {[key:string]:Availability[]} = {};
    public cached_key: string;

    public depositProfile: {deposit_lead:Number, deposit: Number}|null;

    private monthView: CalendarMonthViewDay[];

    constructor(
        private http: HttpService,
        private calendarUtils: CalendarUtils,
        private memoryStorage: MemoryStorage
    ) { }

    ngOnInit(){
        /*if(!this?.date || this.date == undefined){
            this.date = new Date();
        }*/
        this.init();
        //this.updateField.emit({ field: 'date', value: this.date });
        //setTimeout(()=>this.dayClicked({day:{date:this.date,isPast:false,meta:[]}}),1000);
    }

    ngOnChanges(changes: SimpleChanges) {
        //if(!this?.date || this.date == undefined) this.date = new Date();
        this.packages = this.venue.packages.filter(item => item.activity_id == this.activityId || this.activityId === undefined || this.activityId === '');
        if(this.activityId && this.package?.activity_id != this.activityId) this.package = undefined;
        if(this.packages.length == 1){
            this.package = this.packages[0];
        }
        if(changes.activityId?.currentValue != changes.activityId?.previousValue){
            this.getGroupedAvailabilities(this.viewDate,0).subscribe(res => this.refresh.next(true));
        }
        if (
            (
                changes.venue && changes.venue.currentValue && (
                    !changes.venue.previousValue || changes.venue.currentValue.venue_id !== changes.venue.previousValue.venue_id
                )
            ) || (changes.viewDate && changes.viewDate.currentValue && this.viewDate !== this.date && this.venue)
        ) {
            this.getGroupedAvailabilities(this.viewDate,8).subscribe(res => this.refresh.next(1));
        }

        if (changes.sessionId && changes.sessionId && this.sessions) {
            this.setSession(this.sessions.find(item => item.session_id === this.sessionId && item.avail_status !== 'closed'));
        }

        if (changes.package && changes.package.currentValue) this.getAvailabilities();
    }

    ngOnDestroy() {
        clearTimeout(this.timeout);
    }

    public init(){
        let activities = JSON.parse(this.memoryStorage.getItem('activities'));

        this.activities = [];

        if(activities === undefined || activities === null){
            //wait until activities are found
            return setTimeout(()=>this.init(), 100);
        }
        if(this.activityId === '') this.activityId = undefined;
        let activity_ids = {};
        if(this.venue?.packages){
            this.venue.packages.forEach(it=>{
                if(activity_ids[it.activity_id] === undefined){
                    activity_ids[it.activity_id] = {'activity_id':it.activity_id,'name':'', 'order':+it.order}
                }else if (+it.order < activity_ids[it.activity_id].order){
                    activity_ids[it.activity_id].order = +it.order
                }
            
            });
        }
        if(activities){
            activities.forEach(it=>{if(activity_ids[it.activity_id]) this.activities.push({activity_id:it.activity_id, name:it.name, order:activity_ids[it.activity_id].order})});
        }
        this.activities.sort((a,b)=>{if(a.order<b.order) return -1; if(a.order==b.order) return 0; return 1});
 
        this.checkDeposit();
    }

    public beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
        const selectedDate = format(this?.date || new Date(), 'yyyy-MM-dd') 

        body.forEach(day => {
            if (day.isPast) return;

            const date = format(day.date,'yyyy-MM-dd');
            const session = this.availabilities.filter(item => item.start_date === date).find(item => item.avail_status !== 'closed');

            day.cssClass = session ? session.avail_status : 'closed';
            day.meta = [];

            if(this.package?.package_id && session){
                if(!session.package_ids.find(it=>+it == +this.package?.package_id)) day.cssClass = 'closed'; //closing day if package doesn't exist that day
            }

            if (session) day.meta = session.package_ids || this.venue.packages.map(item => item.package_id);
            if (session && session.avail_status !== 'closed' && !this.date){ this.dayClicked({ day }, false);
            }
            if (session && session.avail_status !== 'closed' && this.date && selectedDate === date){
                this.dayClicked({ day });
            }
        });
    }

    public nextStep(): void {
        if (this.session) return this.updateField.emit({ field: 'step', value: 'customer' });

        clearTimeout(this.timeout);

        this.shake = true;
        this.timeout = setTimeout(() => this.shake = false, 820);
    }

    public packageChanged(pack: Package): void {
        this.updateField.emit({ field: 'package', value: pack });
        this.getGroupedAvailabilities(this.viewDate,0).subscribe(res => this.refresh.next(true));
    }

    public activityChanged({activity_id}): void {
        this.updateField.emit({ field: 'activityId', value: activity_id });
    }


    public dayClicked({ day }: { day: CalendarMonthViewDay|any }, reset = true): void {
        let packageIds = [];

        let offset = day.date.getTimezoneOffset();

        let _day = addMinutes(day.date, -offset)
        let _day_string = _day.toISOString().substring(0,10); //otherwise summer time breaks things

        let sessions = this.availabilities.filter(it=>it.start_date == _day_string && it.avail_status != 'closed')
        sessions.forEach(sess=>{packageIds= [...packageIds,...sess.package_ids]})

        this.packages.forEach(pkg=>{
            pkg.enabled = false;
            if(packageIds.find(it=>+it == +pkg.package_id)) pkg.enabled = true
        }); //disable packages that aren't available that day


        if (day.isPast /*|| day.cssClass === 'closed'*/) return;

        let packageAvailable = false;

        if (this.package) packageAvailable = day.meta.includes(+this.package?.package_id);

        this.packages = this.packages.filter(item => day.meta.includes(+item.package_id) || day.meta.includes(item.package_id));

        this.date = day.date;
        this.sessions = [];

        if(sessions.filter(it=>it?.session_id !== null).length == 0){
            this.sessions = sessions; //availability contains the session information
        }

        if (!packageAvailable) {
            if(this.package){
                //console.log(this.package);
                //this.package = undefined
                this.sessions = [];
                this.getAvailabilities(packageAvailable || !reset);
            }else{
                this.package = undefined;
            }
        } else {
            this.getAvailabilities(packageAvailable || !reset);
        }

        this.checkDeposit();

        this.updateField.emit({ field: 'date', value: day.date });
        this.updateField.emit({ field: 'package', value: this.package });
    }

    public setSession(session: Availability): void {
        this.updateField.emit({ field: 'session', value: session });
        if(session === undefined){
            if(this.sessions.length == 1) session = this.sessions[0];
            else return;
        }
        this.session = session;

        session.price_options.forEach(it=>{
            it.min_pax = it.min_pax ?? 1;
            it.qty_requested = it.qty_requested ?? 0;
        })

        if(this.package !== undefined && this.package?.valid_extras){
            this.extras = this.venue.extras.filter(item=>this.package?.valid_extras.find(it=>it == item.package_id)).map(it=>it.package_id);
        }else{
            this.extras = [];
        }

        this.availableExtras = [];

        if(this.extras.length==0){
            this.updateField.emit({ field: 'extras', value: this.availableExtras });
            return;
        }


        const date = format(this.date, 'yyyy-MM-dd');
        combineLatest(this.extras.map(item=>{
        return this.http.get('/availabilities', {
            params: {
                start_date: date,
                end_date: date,
                package_id: item,
                venue_id: this.package.venue_id,
                session_id: this.session.session_id,
                extra:"1"
            }}
         )}))
        .subscribe(res => {
            //only keep those that are available for the package
            let availabilities = [].concat.apply([], res.map(it=>[...it.body.availabilities]))
                                .filter(it=>it.avail_status!='closed');

            this.extras = this.extras.filter(extra=>availabilities.filter(it=>it.package_id==extra).length>0);
            this.availableExtras = this.venue.extras.filter(item=>this.extras.find(it=>it == item.package_id)).map(it=>{
                let avs = availabilities.filter(av=>it.package_id==av.package_id && +av.session_id == +this.session.session_id);
                if(avs.length > 0){
                    it.max_pax = avs[0].max_pax;
                    it.min_pax = avs[0].min_pax;
                }
                return it;
            });

            this.updateField.emit({ field: 'extras', value: this.availableExtras });
        });
    }

    public changeViewDate(direction: 'prev' | 'next', retry:number=0): void {
        if(retry < 0) return;
        let date: Date;

        if (direction === 'prev') {
            date = addMonths(this.viewDate, -1)

            if(isBefore(date,startOfMonth(date))) return;
        } else if (direction === 'next') {
            date = addMonths(this.viewDate, 1)
        }

        this.getGroupedAvailabilities(date,retry).subscribe(() => this.viewDate = date);
    }

    private getAvailabilities(packageAvailable?: boolean): void {
        if(!this.date) {
            //setTimeout(()=>this.getAvailabilities(packageAvailable),1000);
            return;
        }
        const date = format(this.date, 'yyyy-MM-dd')

        if(this.package?.valid_extras)
        this.extras = this.venue.extras.filter(item=>this.package.valid_extras.find(it=>it == item.package_id)).map(it=>it.package_id);

        //caching the call and result so it doesnt happen twice
        let cache_key = [date,date,this.package?.package_id, this.package?.venue_id].join('');
        if(this.cached_key != cache_key) this.cached_key = cache_key;
        else{
            if(this.cached_data[cache_key] === undefined) return; //currently calculating it
        }

        if(this.cached_data[cache_key] !== undefined){
            if(!this.package?.package_id){
                this.setSessions(this.cached_data[cache_key], packageAvailable);    
                this.checkOffer(this.cached_data[cache_key]);
            }
            let package_availability = this.cached_data[cache_key].filter(it=>it?.package_id == this.package?.package_id || it?.package_ids.filter(item=>item == +this.package?.package_id).length>0);

            this.setSessions(package_availability, packageAvailable);
            this.checkOffer(package_availability);
            return;
        } 
        this.sessions = [];

        this.http.get('/availabilities', {
            params: {
                start_date: date,
                end_date: date,
                package_id: this.package?.package_id,
                venue_id: this.package?.venue_id
            }
        }).subscribe(res => {
            //only keep those that are available for the package
            this.cached_data[cache_key] = res.body.availabilities;
            if(!this.package?.package_id){
                this.setSessions(res.body.availabilities, packageAvailable);    
                this.checkOffer(res.body.availabilities);
            }
            let package_availability = res.body.availabilities.filter(it=>it?.package_id == this.package?.package_id || it?.package_ids.filter(item=>item == this.package?.package_id).length>0);

            this.setSessions(package_availability, packageAvailable);
            this.checkOffer(package_availability);
        });
    }

    private checkOffer(sessions: Availability[]): void {
        if (
            this.venue.offer_ids.includes('1') &&
            !this.package.name.toLowerCase().includes('impact') &&
            sessions.find(item => item.session_name.toLowerCase().includes('full day'))
        ) {
            this.freePaintballs = true;
        } else {
            this.freePaintballs = false;
        }
    }

    private setSessions(sessions: Availability[], packageAvailable?: boolean): void {
        this.updateField.emit({ field: 'session', value: this.session });

        this.sessions = this.venue.sessions
            .map(item => sessions.find(val => val.session_id === item.session_id))
            .filter(item => !!item).map(item => {
                const { price } = getOptionsMinMax(this.venue.currency, this.package, this.date, item);
                const priceOptions = filterOptions(this.package, this.date, item);

                let extras :any[] = [];
                
                if(item.package_ids && this.extras) extras = item.package_ids.filter(it=>this.extras?.filter(item=>+item == it).length>0)
                return { ...item, price, price_options: priceOptions, extras:extras};
            });

        if(!this.venue.sessions.length) this.sessions = sessions

        this.sessions = sortSessions(this.sessions);

        if(this.sessions.length == 1){
            this.session = this.sessions[0];
            this.sessionId = this.session.session_id;

            setTimeout(()=>this.setSession(this.session));
        }


        if ((packageAvailable && this.session) || this.sessionId) {
            const sessionId = this.session ? this.session.session_id : this.sessionId;
            const session = this.sessions.find(item => item.session_id === sessionId && item.avail_status !== 'closed');

            if (session && this.session !== undefined) this.session = { ...session, price_options: this.session.price_options };
            else this.session = undefined;
        } else this.session = undefined;

        if(this.venue.company_id === '2' && this.venue.sessions.filter(it=>((it?.name||'').toLowerCase().indexOf(' or ') >= 0)).length > 0){
            
            this.tooltip = `For flexibility the majority of sessions show as 10am or 11am… 1pm or 2pm etc.<br/><br/>
        If you have no preference and are flexible for both then the instructor will decide (according to your group size and selected package) and confirm an exact start time for you on your booking confirmation receipt.<br/><br/>
        If you would prefer a specific time then please send this to us in the messages section through the online booking process and we will do our best to accommodate.`;
        }


    }

    private getGroupedAvailabilities(date: Date, retry: number=1): Observable<HttpResponse<any>> {
        this.availabilityLoading = true;

        const view = this.calendarUtils.getMonthView({ viewDate: date, weekStartsOn: 1 });

        let params :any= {
            start_date: format(view.period.start, 'yyyy-MM-dd'),
            end_date: format(view.period.end,'yyyy-MM-dd'),
            venue_id: this.venue.venue_id,
            activity_id: this.activityId
        }
        /*if(this.package?.package_id){
            params.package_id = this.package?.package_id;
            delete params.activity_id;
        }*/

        return this.http.get('/availabilities', {params:params
        }).pipe(
            tap(res => {
                this.availabilities = res.body.availabilities;
            }),
            finalize(() => {
                if(this.availabilities.length == 0 && retry > 0){
                    this.changeViewDate('next', --retry); //this month has no availability. try the next month
                }else{
                    this.availabilityLoading = false;
                }
            })
        );
    }



    private checkDeposit(): any {
        if(!this.package) return;
        if(!this.memoryStorage.getItem('activities')) return;
        if (!+this.package.accept_deposits || this.venue.company_id !== '2') return;
        
        this.depositProfile = null;
        
        const activity = JSON.parse(this.memoryStorage.getItem('activities')).find(it=>this.package?.activity_id==it.activity_id);
        const pay_profile = JSON.parse(this.memoryStorage.getItem('pay_profile'));

        if(!+this.package.accept_deposits){
            if(true){
                //check if company settings exist
                if(pay_profile.deposit?.deposit || pay_profile.deposit?.deposit_lead|| pay_profile.deposit?.deposit_min || pay_profile.deposit?.deposit_type || pay_profile.deposit?.payment_due){
                    this.package.accept_deposits = true;
                    if(!this.package.deposit) this.package.deposit = pay_profile.deposit.deposit;
                    if(!this.package.deposit_lead) this.package.deposit_lead = pay_profile.deposit.deposit_lead;
                    if(!this.package.deposit_min) this.package.deposit_min = pay_profile.deposit.deposit_min;
                    if(!this.package.deposit_type) this.package.deposit_type = pay_profile.deposit.deposit_type;
                    if(!this.package.payment_due) this.package.payment_due = pay_profile.deposit.payment_due;
                }
            }else{
                this.package.deposit = null;
                this.package.deposit_lead = null;
            }
        }

        if (!+this.package.accept_deposits || this.venue.company_id !== '2') return;

        if(activity?.deposit_profile == -1) return;

        let depositProfile = pay_profile.deposit_profile[activity?.deposit_profile]??null;

        if(!depositProfile) return;

        if(+this.package.price < +depositProfile.deposit) return;

        if(addDays(startOfDay(new Date), depositProfile.deposit_lead) > startOfDay(this.date)) return;
        this.depositProfile = depositProfile;
        
    }

}
