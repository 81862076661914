import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { tap, switchMap, finalize } from 'rxjs/operators';
//import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faAlignLeft } from '@fortawesome/free-solid-svg-icons/faAlignLeft';
import { faDumbbell } from '@fortawesome/free-solid-svg-icons/faDumbbell';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { TranslateService } from '@ngx-translate/core';

import { getOptionsMinMax } from '../../helpers/price-options.helpers';
import { Package, Availability, Venue } from 'src/app/models/venues.model';
import { HttpService } from 'src/app/services/http.service';
import { PaymentService } from 'src/app/services/payment.service';
import { combineLatest } from 'rxjs';
import { MemoryStorage } from 'src/app/services/memory-storage.service';
import { format } from 'date-fns/format';
import { parse } from 'date-fns/parse';

//declare var fbq: any;

function getParameterByName(name: string) {
    const url = (window.location !== window.parent.location) ? document.referrer : document.location.href;

    name = name.replace(/[\[\]]/g, '\\$&');

    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);

    if (!results || !results[2]) return null;

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

@Component({
    templateUrl: './bookit.component.html',
})
export class BookitComponent implements OnChanges, OnInit {
    @Input() bookingId: string;
    @Input() guestKey: string;
    @Input() guest: string;
    @Input() brandId: string;
    @Input() venueId: string;
    @Input() activityId: string;
    @Input() packageId: string;
    @Input() sessionId: string;
    @Input() dateStr: string;
    @Input() height: string;
    @Input() duration = '0';
    @Input() message = 'This availability will be held on your behalf for';
    @Input() primaryColor = '#cc0000';
    @Input() backgroundColor = 'inherit';
    @Input() accentColor = '#4a5568';
    @Input() textColor = '#inherit';
    @Input() baseUrl = 'https://geronigo.gitlab.io/bookit-light/#/';
    @Input() inline = '1';
    @Input() fbad: string;
    @Input() gclid: string;
    @Input() bingad: string;
    @Input() affiliateId: string;
    @Input() adId: string;

    @Output() buyVoucher: EventEmitter<void> = new EventEmitter();
    @Output() brandsLoaded: EventEmitter<any[]> = new EventEmitter();

    public chevronDown = faChevronDown;
    public date: Date;
    public booking: any;
    public viewDate: Date = new Date();
    public venue: Venue;
    public package: Package;
    public extras: Package[];
    public session: Availability;
    public activities: any[] = [];
    public step: 'booking'|'customer'|'payment'|'error'|'booking_fields';
    public force_step: string;
    public brand: any;
    public company: any;
    public enquiryLoading = false;
    public form: any;
    public priceOptions: any[] = [];
    public payProfiles: any;
    public terms: any[] = [];
    public promo: any;
    public vouchers: any[] = [];
    public showArrow = false;
    public groupTypes: [];

    private loading = false;

    constructor(
        private http: HttpService,
        private sanitizer: DomSanitizer,
        private toastr: ToastrService,
        private translate: TranslateService,
        private payment: PaymentService,
        private memoryStorage: MemoryStorage
    ) {
        // tslint:disable-next-line: no-string-literal
        window['dataLayer'] = window['dataLayer'] || [];

        this.translate.setDefaultLang('en');
    }

    ngOnInit() {
        this.brandId = getParameterByName('brand-id') || this.brandId;
        this.venueId = getParameterByName('venue-id') || this.venueId;
        this.activityId = getParameterByName('activity-id') || this.activityId;
        this.packageId = getParameterByName('package-id') || this.packageId;
        this.sessionId = getParameterByName('session-id') || this.sessionId;
        this.dateStr = getParameterByName('date-str') || this.dateStr;
        this.primaryColor = getParameterByName('primary-color') || this.primaryColor;
        this.accentColor = getParameterByName('accent-color') || this.accentColor;
        this.textColor = getParameterByName('text-color') || this.textColor;
        let step = getParameterByName('step');
        if(step == 'booking' || step =='customer' || step == 'payment'||step =='error'||step =='booking_fields')
            this.step = step;
            this.force_step = step;
        this.extras = [];

        //if (this.dateStr) this.date = this.viewDate = moment(this.dateStr).toDate();
        if (this.dateStr) this.date = this.viewDate = parse(this.dateStr,'yyyy-MM-dd',new Date());

        //check url
        let queryString = new URLSearchParams(window.location.search);
        let q = new URLSearchParams(queryString);
        if(q.get('mode')!== undefined){
            this.mode = q.get('mode') == 'test' ? 'test' : 'live';
        }

        if(q.get('versions')!== undefined && q.get('versions')!==null){
            this.http.setApi(q.get('versions'));
        }

        if(q.get('booking_id')){
            this.bookingId = q.get('booking_id');
        }
        if(q.get('guest_key')){
            this.guestKey = q.get('guest_key');
        }

        this.getData();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.lang && changes.lang.currentValue) this.translate.use(changes.lang.currentValue.toLowerCase());

        if (
            (changes.venueId && !changes.venueId.firstChange && changes.venueId.currentValue) ||
            (changes.activityId && !changes.activityId.firstChange && changes.activityId.currentValue) ||
            (changes.brandId && !changes.brandId.firstChange && changes.brandId.currentValue)
        ) {
            this.getData();
        }

        if (changes.dateStr && !changes.dateStr.firstChange && changes.dateStr.currentValue) {
            this.date = this.viewDate = parse(this.dateStr, 'yyyy-MM-dd', new Date());
        }

        if (changes.packageId && !changes.packageId.firstChange && changes.packageId.currentValue && this.venue) {
            this.package = this.venue.packages.find(item => item.package_id === this.packageId);
        }

        if(this.package){
            this.activityId = this.package.activity_id;
        }
    }

    @Input()
    set lang(lang: string) { this.http.lang = lang; }
    get lang(): string { return this.http.lang; }

    @Input()
    set mode(mode: any) { this.http.mode = mode; }
    get mode(): any { return this.http.mode; }

    @Input()
    set originApp(val: string) { this.http.extraParams.origin_app = val; }
    get originApp(): string { return this.http.extraParams.origin_app; }

    @Input()
    set originCta(val: string) { this.http.extraParams.origin_cta = val; }
    get originCta(): string { return this.http.extraParams.origin_cta; }

    @Input()
    set apiUrl(val: string) { this.http.baseURL = val; }
    get apiUrl(): string { return this.http.baseURL; }

    @Input()
    set apiKey(val: string) { this.http.apiKey = val || this.http.apiKey; }
    get apiKey(): string { return this.http.apiKey; }

    @Input()
    set appKey(val: string) { this.http.appKey = val || this.http.appKey; }
    get appKey(): string { return this.http.appKey; }

    @Input()
    set formSettings(val: string) { this.form = val ? JSON.parse(val) : this.form; }
    get formSettings(): string { return this.form; }

    public updateField({ field, value }: { field: string, value: any }): void {
        if(field == 'session' && value === undefined) return; //not sure why it gets unset. this breaks stuff
        this[field] = value;

        if (field === 'date') {
            this.venue = {
                ...this.venue,
                packages: this.venue.packages
                    .map(item => {
                        const { price: minMax } = getOptionsMinMax(this.venue.currency, item, this.date);

                        return { ...item, min_max: minMax };
                    })
            };
        }

        setTimeout(() => this.checkHeight());
    }

    public submitEnquiry({ form, priceOptions }: { form: any, priceOptions: any[] }, status = 'enquiry'): void {
        if(this.booking !== undefined && status != 'enquiry'){
            status = this.booking.status;
            /*if(status == 'cart'){
                status = 'enquiry';
            }*/
        }


        if (this.enquiryLoading) return;
        this.enquiryLoading = true;

        const data: any = {
            ...form,
            customer_name: [form.first_name, form.last_name].filter(Boolean).join(' '),
            _sub: 'all',
            cb: 'enquiry_submitted_track_v2',
            method: 'post',
            resource: 'bookings',
            action: 'create',
            force_comms: 1,
            newsletter: form.subscribed ? '1' : '0',
            subscribed: form.subscribed,
            customer_latitude: null,
            customer_longitude: null,
            geronigo_widget: '1',
            theme_code: 'geronigo',
            currency: this.venue.currency,
            origin_url: window.location.origin,
            referrer: document.referrer,
            _target: 'bookings',
            booking_terms: 1,
            transactions: [],
            brand_id: this.brandId || '',
            originally: status,
            fbad: this.fbad || '',
            gclid: this.gclid || '',
            bindad: this.bingad || '',
            affiliate_id: this.affiliateId || '',
            _tracker: {
                fbad: this.fbad || false,
                gclid: this.gclid || false,
                bindad: this.bingad || false,
                affiliate_id: this.affiliateId || false
            },
            status,
            components: [
                {
                    qty_requested: priceOptions.reduce((acc, item) => acc + item.qty_requested, 0),
                    venue_id: this.venue.venue_id,
                    activity_id: this.package?.activity_id,
                    package_id: this.package?.package_id,
                    with_package_id: this.package?.package_id,
                    session_id: this.session?.session_id,
                    availability_id: this.session?.availability_id,
                    package_name: this.package?.name,
                    session_name: this.session?.name,
                    package_price: this.package?.price,
                    start_date: format(this.date,'yyyy-MM-dd'),
                    price_options: priceOptions,
                    // held_for: +this.duration || null
                }
            ]
        };

        let extras = this.extras.filter(it=>it.qty_requested>0).map(it=>{return {
                qty_requested:it?.qty_requested,
                venue_id:it?.venue_id,
                activity_id:it?.activity_id,
                package_id:it?.package_id,
                with_package_id:this.package?.package_id,
                session_id: this.session?.session_id,
                package_name: it?.name,
                session_name: this.session?.name,
                package_price: it.price,
                start_date: format(this.date, 'yyyy-MM-dd'),
        }});
        if(extras.length>0) data.components = [...data.components,...extras];

        if (this.booking) {
            data.booking_id = this.booking.booking_id;
            data.components = data.components.map((item, i) => 
                {
                    if(this.booking.components[i] && this.booking.components[i].component_id)
                        item.component_id = this.booking.components[i].component_id;
                    return item;
                });
        }

        this.http.post('/bookings', data).pipe(
            finalize(() => this.enquiryLoading = false)
        ).subscribe(res => {
            const booking = res.body.bookings[0];

            this.booking = booking;

            // tslint:disable-next-line: no-string-literal
            window['dataLayer'].push({
                event: status === 'enquiry' ? 'conversion_enquiry' : 'conversion_cart_booking',
                conversion_type: status === 'enquiry' ? 'enquiry' : 'cart_booking',
                order_id: booking.booking_id,
                value: booking.total_cost,
                label: status === 'enquiry' ? 'enquiry' : 'booking',
                currency: booking.currency,
                category: 'conversion',
                product_ids: `v${this.venue?.venue_id}va${this.package?.activity_id}ap${this.package?.package_id}p`,
                action: status === 'enquiry' ? 'submitted' : 'cart'
            });

            /*if (typeof fbq !== 'undefined') {
                const fbqData = {
                    value: booking.total_cost,
                    currency: booking.currency,
                    content_category: 'booking',
                    content_type: 'product',
                    contents: [
                        {
                          id: `v${this.venue.venue_id}va${this.package.activity_id}ap${this.package.package_id}p`,
                          quantity: booking.qty_requested
                        }
                    ],
                    product_ids: `v${this.venue.venue_id}va${this.package.activity_id}ap${this.package.package_id}p`
                };

                if (status === 'enquiry') fbq('track', 'Lead', fbqData);
                else fbq('track', 'AddToCart', fbqData);
            }*/

            if (status === 'enquiry') {
                this.toastr.success('Enquiry submitted. Redirecting...');
		let url = `https://rezbot.com/${this.booking.guest_key}/${this.booking.booking_id}`;

		if (this.booking.company_id == 2) {
			const themeUrl = this.brand && this.brand.theme_website ? this.brand.theme_website : 'https://geronigo.com';
			url = `${themeUrl}/en/confirmation/bookingID-${this.booking.booking_id}_guestKey-${this.booking.guest_key}`;
		}

		window.location.href = url;
            } else {
                this.booking = booking;
                if(this.force_step != 'booking_fields'){
                    this.step = 'payment';
                }
            }
        });
    }

    public book({ form, priceOptions }: { form: any, priceOptions: any[] }): void {
        const version = this.http.isTestMode ? 'test' : 'live';
        const profiles = (this.payProfiles[this.venue.pay_profile] || this.payProfiles.default)[version];

        if (this.inline === '1' && profiles && Object.values(profiles).length) {
            this.priceOptions = priceOptions;
            this.submitEnquiry({ form, priceOptions }, 'cart');

            return;
        }

        this.enquiryLoading = true;

        const params = [
            `venue_id=${this.venue.venue_id}`,
            `package_id=${this.package?.package_id}`,
            `activity_id=${this.package?.activity_id}`,
            `brand_id=${this.brand ? this.brand.id : ''}`,
            `session_id=${this.session.session_id}`,
            `date=${format(this.date,'yyyyMMdd')}`,
            `name=${encodeURIComponent(form.customer_name)}`,
            `email=${encodeURIComponent(form.customer_email)}`,
            `tel=${encodeURIComponent(form.customer_tel)}`,
            `title=${encodeURIComponent(form.title)}`,
            `group_type=${encodeURIComponent(form.group_type)}`,
            `message=${encodeURIComponent(form.message)}`,
            `tel=${encodeURIComponent(form.customer_tel)}`,
        ];

        priceOptions.forEach((item, i) => params.push(`price_options=${item.name}_${item.qty_requested}`));

        window.open(`${this.baseUrl}?${params.join('&')}`, '_blank');

        this.enquiryLoading = false;
    }

    public scrollToBottom(): void {
        if (!this.height) return;

        const container = document.querySelector('#rezbot-scroller');

        if (!container) return;

        container.scrollTop = container.scrollHeight;
    }

    public checkHeight(): void {
        if (!this.height) return;

        const container = document.querySelector('#rezbot-scroller') as any;

        if (!container) return;

        this.showArrow = container.scrollHeight > +this.height && container.scrollTop < container.scrollTopMax;
    }

    private getDataFromBooking(): void{
        this.http.get('/bookings', {
            headers:{ 'X-API-KEY': this.guestKey },
            params: { booking_id: this.bookingId }
        }).subscribe({'next':(it)=>{
            this.booking = it.body.bookings[0];
            let index = (this.booking?.components||[]).findIndex(it=>+it.venue_id != 0 && +it.package_id != 0)
            if(index > -1){
                this.venueId = this.booking.components[index].venue_id;
                this.activityId = this.booking.components[index].activity_id;
                this.packageId = this.booking.components[index].package_id;
                this.sessionId = this.booking.components[index].session_id;
                this.package = this.booking.components[index];
                this.priceOptions = this.booking.components[index].price_options;
            }

            this._getData();
            if(index > -1){
                this.waitForData();
            }
        }});
    }

    private waitForData(){
        if(this.venue !== undefined && this.payProfiles!== undefined && this.activities !== undefined){
            if(+this.booking.test){
                this.http.mode = 'test'}else{
                this.http.mode = 'live';
            };
            this.payment.init(this.venue?.pay_profile, this.payProfiles); //set the payment
            setTimeout(()=>{                
                if(this.sessionId){
                    let sess_index = (this.venue?.sessions||[]).findIndex(it=>it?.session_id == this.sessionId);
                    if(sess_index > -1){
                        this.session = this.venue.sessions[sess_index];
                    }
                }

                if(this.force_step != 'booking_fields'){
                    this.step = 'payment';
                }
            }); //skipt straight to 3rd option
        }else{
            setTimeout(()=>{
                this.waitForData()
            }, 500);
        }
    }

    private getData(): void {
        if(this.bookingId && this.guestKey){
            return this.getDataFromBooking();
        }
        return this._getData();
    }

    private _getData(){
        if (this.loading) return;
        this.loading = true;

        this.reset();

        if (!this.venueId) return console.error('venue-id is required');

        this.http.get('/venues', {
            params: { venue_id: this.venueId }
        }).pipe(
            tap(res => {
                const venue: Venue = res.body.venues[0];

                this.venue = {
                    ...venue,
                    packages: venue.packages
                        .filter(item => item.extra === '0' && item.active === '1' && item.bookable === '1')
                        .filter(item => this.activityId ? item.activity_id === this.activityId : true)
                        .map(item => {
                            const { price: minMax } = getOptionsMinMax(venue.currency, item, this.date);
                            const restrictions: {[key: string]: { type: string, min?: string, max?: string }} = {};
                            const icons = { group: faUsers, age: faUser, height: faAlignLeft, weight: faDumbbell };

                            [
                                { name: 'Min. Group', descriptor: item.min_pax },
                                { name: 'Max. Group', descriptor: item.max_pax },
                                { name: 'Min. Age', descriptor: item.min_age },
                                { name: 'Max. Age', descriptor: item.max_age },
                                ...(item.restrictions || [])
                            ].filter(val => val.descriptor && val.descriptor !== '0').forEach(val => {
                                const restriction = val.name.replace('Min. ', '').replace('Max. ', '');
                                const type = val.name.includes('Min. ') ? 'min' : 'max';

                                if (!restrictions[restriction]) restrictions[restriction] = { type: restriction };

                                if (!restrictions[restriction][type]) restrictions[restriction][type] = val.descriptor;
                            });

                            return {
                                ...item,
                                desc: this.sanitizer.bypassSecurityTrustHtml(item.desc.replace(/\n/g, '<br />')),
                                min_max: minMax,
                                restrictions: Object.values(restrictions).map(val => {
                                    let descriptor: string;

                                    if (val.min && val.max) descriptor = `${val.min} to ${val.max}`;
                                    else if (val.max) descriptor = `Up to ${val.max}`;
                                    else if (val.min) descriptor = `From ${val.min}`;

                                    return { name: val.type, descriptor, icon: icons[val.type.toLowerCase()] || null };
                                })
                            };
                        }),
                        extras: venue.packages
                        .filter(item => item.extra === '1' && item.active === '1' && item.bookable === '1')
                        .filter(item => this.activityId ? item.activity_id === this.activityId : true)
                };

                if (this.packageId) this.package = this.venue.packages.find(item => item.package_id === this.packageId);

                if(this.step != 'booking_fields'){
                    this.step = 'booking';
                }

                if(this.packageId && this.force_step == 'booking_fields'){
                    this.step = 'booking_fields';
                }
            }),
            switchMap(() => {
                return combineLatest([
                    this.http.get('/directories', {
                        params: { directories: 'brands,pay_profile,texts,activities,group_types' }
                    }),
                    this.http.get('/companies', {
                        params: { company_id: 'self' }
                    })
                ])
            }),
            finalize(() => this.loading = false)
        ).subscribe({next:([res, companyRes]) => {
            const { brands, pay_profile, texts, activities, group_types } = res.body;
            const id = this.brandId || '4696';

            this.brandsLoaded.emit(brands);

            this.company = companyRes.body.companies[0];
            this.payment.currency = this.venue.currency;

            this.venue.packages = this.venue.packages.map(val => {
                if (+val.deposit && val.deposit_lead) return val;

                const activity = activities.find(item => item.activity_id === val.activity_id);

                if (!activity) return val;

                const depositProfile = pay_profile.deposit_profile[activity.deposit_profile];

                if (!depositProfile) return val;

                return { ...val, deposit: depositProfile.deposit, deposit_lead: depositProfile.deposit_lead};
            });

            this.brand = brands.find(item => item.id === id);
            this.payProfiles = pay_profile;
            this.activities = activities;

            this.memoryStorage.setItem('activities', JSON.stringify(this.activities));
            this.memoryStorage.setItem('pay_profile', JSON.stringify(pay_profile));
            this.memoryStorage.setItem('group_types', JSON.stringify(group_types));


            this.groupTypes = [].concat.apply([],group_types.map(it=>(Object.keys(it).map((it2)=>({id:it2, text:it[it2]})))));

            this.terms = texts.terms_booking.map(item => ({
                ...item,
                text: this.sanitizer.bypassSecurityTrustHtml(item.text)
            }));
        }, 
        error: (err) => {
            console.error(err);
            this.step = 'error';
        }
    });
    }

    private reset(): void {
        this.date = undefined;
        this.venue = undefined;
        this.package = undefined;
        this.step = undefined;
        this.brand = undefined;
    }
}
